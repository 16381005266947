<template>
  <div
    class="usdt container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <van-loading v-if="loading" />
    <div
      class="cardlist shadow border-r5 mt-10 noflex"
      v-for="item in coinWallet"
      :key="item.id"
    >
      <div class="con tophead">
        <van-icon name="card" style="margin-left: 6px" />
        <span>{{ item.address }}</span>
      </div>
      <div class="line fz14 con">
        {{ $t("bank.text17") }}:{{ item.type_name }}
      </div>
      <div class="fz14 con"></div>
      <!-- <a class="link">{{ $t("bank.text16")}}</a> -->
    </div>
    <div
      class="tophead shadow default border-r5 con mt-10"
      @click="goPage('add')"
      v-if="!loading && !coinWallet.length"
    >
      <van-icon name="paid" style="margin-left: 6px" />
      <span>{{ $t("bank.text12") }}</span>
      <van-icon name="plus" style="font-size: 14px" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { getCoinWallet } from "@/api/user";
import { useRouter } from "vue-router";
const router = useRouter();
const loading = ref(true);
const coinWallet = ref([]);
const goPage = (type) => {
  router.push({ name: "usdtAdd", query: { type: type } });
};
const getData = () => {
  getCoinWallet()
    .then((res) => {
      coinWallet.value = res.data.user_wallet;
      console.log(res);
      loading.value = false;
    })
    .finally(() => {
      loading.value = false;
    });
};
getData();
</script>

<style lang="less" scoped>
.usdt {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;
  min-height: 300px;
  .cardlist {
    background: linear-gradient(180deg, #302e55, #514f79);
  }
  .tophead {
    display: flex;
    justify-content: left;
    /* 水平居中 */
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    i:first-child {
      font-size: 40px;
      margin-right: 20px;
    }
    span {
      width: 80%;
    }
  }

  .line {
    background: #2d2c58;
    color: #bbb;
    margin: 10px 0;
    padding: 10px;
    border-top: 1px solid #27273a;
    border-bottom: 1px solid #66658f;
  }
}
</style>
